import './SettingsPrivacyContent.less'

import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SortableContext, useSortable } from '@dnd-kit/sortable'
import { Button } from 'antd'
import { ContentState } from 'draft-js'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { CustomConsent, deleteCustomConsent, fetchCustomConsents, updateCustomConsent } from '../../../../requests'
import { DatacIcon, DatacLoading, DatacMessage, DatacTitle } from '../../../common'
import { EditorOutput } from '../../../shared/Editor'
import { SettingsLayout } from '../../SettingsLayout'
import { SettingsPrivacyConsentModal } from './SettingsPrivacyConsentModal'

const maxConditions = 5

export const SettingsPrivacyContent: React.FC = () => {
  const intl = useScopedIntl('')
  const intlPrivacy = useScopedIntl('settings.privacy')
  const [consents, setConsents] = useState<CustomConsent[]>([])
  const [isFetchingConsents, setIsFetchingConsents] = useState(false)
  const [consentToView, setConsentsToView] = useState<CustomConsent>(null)
  const [isAddModalOpened, setIsAddModalOpened] = useState(false)

  useEffect(() => {
    fetchConsents()
  }, [])

  const fetchConsents = () => {
    setIsFetchingConsents(true)
    fetchCustomConsents(
      {},
      {
        onSuccess: consents => {
          setConsents(consents)
          setIsFetchingConsents(false)
        },
        onRequestError: code => {
          setIsFetchingConsents(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  const onDelete = (id: number) => {
    setIsFetchingConsents(true)
    deleteCustomConsent(
      { id },
      {
        onSuccess: () => fetchConsents(),
        onRequestError: code => {
          setIsFetchingConsents(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  const onModalClose = () => {
    setIsAddModalOpened(false)
    setConsentsToView(null)
    fetchConsents()
  }

  const SortableItem = ({ consent }: { consent: CustomConsent }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: consent.id })

    const style = transform
      ? {
          transform: `translate3d(${transform?.x}px, ${transform?.y}px, 0)`,
          transition
        }
      : undefined

    return (
      <li className="settings-privacy-content__consent" ref={setNodeRef} style={style} key={consent.id}>
        <div className="settings-privacy-content__consent__handle" {...listeners} {...attributes}>
          <DatacIcon name="menu" type="grey" /> <EditorOutput content={consent.body as ContentState} />
        </div>
        <DatacIcon name="edit" type="blue" onClick={() => setConsentsToView(consent)} />
        <DatacIcon name="trash" type="red" onClick={() => onDelete(consent.id)} />
      </li>
    )
  }

  const SortableList: React.FC<{ consents: CustomConsent[] }> = ({ consents }) => (
    <ul>
      <SortableContext items={consents.map(consent => consent.id)}>
        {consents.map(consent => (
          <SortableItem key={consent.id} consent={consent} />
        ))}
      </SortableContext>
    </ul>
  )

  const onSortEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (over && active?.id !== over?.id) {
      setIsFetchingConsents(true)
      const oldIndex = consents.findIndex(consent => consent.id === active?.id)
      const newIndex = consents.findIndex(consent => consent.id === over?.id)
      updateCustomConsent(
        { ...consents[oldIndex], order: newIndex },
        {
          onSuccess: () => {
            fetchConsents()
          },
          onRequestError: code => {
            DatacMessage.genericError(intl, code)
            setIsFetchingConsents(false)
          }
        }
      )
    }
  }

  return (
    <SettingsLayout>
      <div className="settings-privacy-content">
        <div className="settings-privacy-content__header">
          <div>
            <DatacTitle type="h2">{intlPrivacy('title')}</DatacTitle>
            <div className="settings-privacy-content__header__description">{intlPrivacy('description')}</div>
          </div>
          {consents?.length < maxConditions && (
            <Button
              className="settings-privacy-content__header-button settings-privacy-content__add-button"
              type="primary"
              size="large"
              onClick={() => setIsAddModalOpened(true)}
            >
              <DatacIcon type="white" name="plus" size="big" />
              {intlPrivacy('add_consent')}
            </Button>
          )}
        </div>
        <div className="settings-privacy-content__consents">
          <DatacLoading isLoading={isFetchingConsents} transparent>
            {consents.length ? (
              <DndContext onDragEnd={onSortEnd}>
                <SortableList consents={consents} />
              </DndContext>
            ) : (
              intlPrivacy('no_consents')
            )}
          </DatacLoading>
        </div>
      </div>
      <SettingsPrivacyConsentModal
        isOpened={isAddModalOpened || !!consentToView}
        consent={consentToView}
        onClose={onModalClose}
      />
    </SettingsLayout>
  )
}
